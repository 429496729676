import React from "react"; 
import './css/spinner.css';
function spinner(){
    return(
        <div className="app">
        <div className="tag-list">
          <div className="loop-slider" style={{ '--duration': '15951ms', '--direction': 'normal' }}>
            <div className="inner">
              <div className="tag"><span>#</span> JavaScript</div>
              <div className="tag"><span>#</span> webdev</div>
              <div className="tag"><span>#</span> Typescript</div>
              <div className="tag"><span>#</span> Next.js</div>
              <div className="tag"><span>#</span> UI/UX</div>
              <div className="tag"><span>#</span> JavaScript</div>
              <div className="tag"><span>#</span> webdev</div>
              <div className="tag"><span>#</span> Typescript</div>
              <div className="tag"><span>#</span> Next.js</div>
              <div className="tag"><span>#</span> UI/UX</div>
            </div>
          </div>
          <div className="loop-slider" style={{ '--duration': '19260ms', '--direction': 'reverse' }}>
            <div className="inner">
              <div className="tag"><span>#</span> webdev</div>
              <div className="tag"><span>#</span> Gatsby</div>
              <div className="tag"><span>#</span> JavaScript</div>
              <div className="tag"><span>#</span> Tailwind</div>
              <div className="tag"><span>#</span> Typescript</div>
              <div className="tag"><span>#</span> webdev</div>
              <div className="tag"><span>#</span> Gatsby</div>
              <div className="tag"><span>#</span> JavaScript</div>
              <div className="tag"><span>#</span> Tailwind</div>
              <div className="tag"><span>#</span> Typescript</div>
            </div>
          </div>
          <div className="loop-slider" style={{ '--duration': '10449ms', '--direction': 'normal' }}>
            <div className="inner">
              <div className="tag"><span>#</span> animation</div>
              <div className="tag"><span>#</span> Tailwind</div>
              <div className="tag"><span>#</span> React</div>
              <div className="tag"><span>#</span> SVG</div>
              <div className="tag"><span>#</span> HTML</div>
              <div className="tag"><span>#</span> animation</div>
              <div className="tag"><span>#</span> Tailwind</div>
              <div className="tag"><span>#</span> React</div>
              <div className="tag"><span>#</span> SVG</div>
              <div className="tag"><span>#</span> HTML</div>
            </div>
          </div>
          <div className="loop-slider" style={{ '--duration': '16638ms', '--direction': 'reverse' }}>
            <div className="inner">
              <div className="tag"><span>#</span> Gatsby</div>
              <div className="tag"><span>#</span> HTML</div>
              <div className="tag"><span>#</span> CSS</div>
              <div className="tag"><span>#</span> React</div>
              <div className="tag"><span>#</span> Next.js</div>
              <div className="tag"><span>#</span> Gatsby</div>
              <div className="tag"><span>#</span> HTML</div>
              <div className="tag"><span>#</span> CSS</div>
              <div className="tag"><span>#</span> React</div>
              <div className="tag"><span>#</span> Next.js</div>
            </div>
          </div>
          <div className="loop-slider" style={{ '--duration': '15936ms', '--direction': 'normal' }}>
            <div className="inner">
              <div className="tag"><span>#</span> Next.js</div>
              <div className="tag"><span>#</span> React</div>
              <div className="tag"><span>#</span> webdev</div>
              <div className="tag"><span>#</span> Typescript</div>
              <div className="tag"><span>#</span> Gatsby</div>
              <div className="tag"><span>#</span> Next.js</div>
              <div className="tag"><span>#</span> React</div>
              <div className="tag"><span>#</span> webdev</div>
              <div className="tag"><span>#</span> Typescript</div>
              <div className="tag"><span>#</span> Gatsby</div>
            </div>
          </div>
          <div className="fade"></div>
        </div>
      </div>
    );
}
export default spinner;