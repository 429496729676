import React from "react";
import './css/Landing.css';
import LOGO from '../Icon/logo.png';

function Landing() {
  return (
    <div className="Landing">
      <div className="fdi">
        <img src={LOGO} alt="logo" />
        <div className="typewriter">
          <h1>Devlox.online</h1>
        </div>
      </div>
    </div>
  );
}

export default Landing;
