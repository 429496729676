import React from 'react';
import Header from './components/Header.js'; 
import Spinner from './components/spinner.js';
import Landing from './components/Landing.js';
import './App.css';

function App() {
  return (
    
    <div className="App">
     <Header/> 
<Landing/>
     <Spinner/> 
   
</div>
  );
}

export default App;
