import React from 'react';
import { FaBars } from 'react-icons/fa';
import './css/Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="container">
      
      <h1 className="logo">
     
          Devlox.online
        </h1>
        <nav>
          <ul className="nav-links">
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <FaBars className="menu-icon" />
      </div>
    </header>
  );
};

export default Header;
